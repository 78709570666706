<script setup>
import { defineProps, ref } from "vue"
import SwitchInput from "../common/SwitchInput.vue"
// import { BCard, BButton} from 'bootstrap-vue-next'

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});


const errorImage = ref(false);

</script>
<template>
  <div class="card" style="">
    <img
        v-if="errorImage || !product.image"
        src="@/assets/img/emptyimg.svg" class="card-img-top" alt="...">
      <img
        v-else
        :src="props.product.image" class="card-img-top" alt="...">
    <div class="card-body">
      <div class="card-title  fw-bold text-uppercase">{{ props.product.title }}</div>
      <div class="card-text truncate">Productor: {{ props.product.producer }}</div>
      <p class="card-text content-description truncate">
        {{ product.description }}
      </p>

      <p v-if="props.product.status != 'aceptado'"  class="aprobation-status" v-text="'Pendiente de aprobación'"/>

      <SwitchInput :isChecked="props.product.active" :productId="props.product.id" />
      <div class="d-inline-flex gap-2 pt-4 w-full justify-content-end ">
        <button 
          @click="$emit('delete', props.product.id)" 
          type="button" class="btn btn-outline-danger btn-sm"
        >
          <i class="fas fa-trash"></i> Borrar
        </button>
        <button 
          @click="$emit('edit', props.product.id)"
          type="button"
          class="btn btn-outline-dark btn-sm"
        >
          <i class="fas fa-edit"></i> Editar
        </button>
        <button 
          @click="$emit('affiliates', props.product.id)"
          type="button"
          class="btn btn-dark btn-sm"
        >
          Afiliados
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #6c757d;
}
.content-description {
  min-height: 65px !important;
}

.card-title {
  font-weight: 900;
  font-family: 'Poppins';
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #464545;
}

.aprobation-status {
  background: #FF5722;
    max-width: 56%;
    font-size: 14px;
    border-radius: 5px;
    color: white;
    padding: 2px 9px;
}
</style>