import axios from "axios";
import { defineStore } from "pinia";

export default defineStore({
  id: "form",
  state: () => ({
    _status: null,
    _error: null,
    _token: localStorage.getItem("token") || null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    token: (state) => state._token,
  },
  actions: {
    async login(payload) {
      this.changeStatus("loading");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/auth0-login/`,
          payload
          //   {
          //     headers: {
          //       Authorization: `Bearer ${localStorage.getItem("token")}`,
          //     },
          //   }
        );

        if (response.status === 200) {
          this.changeStatus("ready");

          this._token = response.data.access_token;
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
        }

        this.changeStatus("ready");
      } catch (error) {
        localStorage.removeItem("token");
        this._token = null;
        this.changeStatus("error", error.response.data);
      }
    },
    async refreshToken() {
      this.changeStatus("loading");
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/auth0-refresh-token/`,
          {
            refresh_token: refreshToken,
          },
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        if (response.status === 200) {
          this._token = response.data.access_token;
          localStorage.setItem("token", response.data.access_token);
          this.changeStatus("ready");
        }
      } catch (error) {
        this.changeStatus("error", error.response.data);
      }
    },
    logout() {
      this.changeStatus("loading");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      this._token = null;
      this.changeStatus("ready");

      window.location.href = "/";
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
