<!-- CreatePayoutDialog.vue -->
<script setup>
import { ref, defineProps, computed, onMounted, onUnmounted } from "vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
  onSubmit: {
    type: Function,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  producers: {
    type: Array,
  },
});
const amount = ref("");
const searchQuery = ref("");
const selectedProducer = ref(null);
const isSelectOpen = ref(false);
const searchInputRef = ref(null);
const selectContainerRef = ref(null);

const filteredProducers = computed(() => {
  if (!searchQuery.value) return props.producers;
  return props.producers.filter((producer) =>
    producer.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

async function  handleSubmit () {
  if (!amount.value || !selectedProducer.value) return;
  
  props.onSubmit(Number(amount.value),selectedProducer.value.id );
  resetForm();
}

function handleClose() {
  resetForm();
  props.onClose();
}

function resetForm() {
  amount.value = "";
  selectedProducer.value = null;
  searchQuery.value = "";
  isSelectOpen.value = false;
}

function selectProducer(producer) {
  selectedProducer.value = producer;
  searchQuery.value = producer.name;
  isSelectOpen.value = false;
}

function handleSelectClick() {
  isSelectOpen.value = true;
  // Use nextTick to ensure the input is rendered before focusing
  setTimeout(() => {
    if (searchInputRef.value) {
      searchInputRef.value.focus();
    }
  }, 0);
}

// Close dropdown when clicking outside of the select container
function handleClickOutside(event) {
  // Only proceed if the select is open
  if (!isSelectOpen.value) return;

  // Check if the click is inside the select container
  if (
    selectContainerRef.value &&
    !selectContainerRef.value.contains(event.target)
  ) {
    isSelectOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener("mousedown", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("mousedown", handleClickOutside);
});
</script>

<template>
  <div v-if="isOpen" class="dialog-overlay" @click="handleClose">
    <div class="dialog-content" @click.stop>
      <div class="dialog-header">
        <h2 class="dialog-title">Crear Nuevo Pago</h2>
        <button class="close-button" @click="handleClose">&times;</button>
      </div>

      <div class="dialog-body">
        <p class="dialog-description">
          Seleccione un productor e ingrese el monto del pago que desea
          realizar.
        </p>

        <!-- Custom Select Input -->
        <div class="input-group">
          <label for="producer">Productor</label>
          <div class="select-container" ref="selectContainerRef">
            <div
              class="select-trigger"
              @click="handleSelectClick"
              v-if="!isSelectOpen"
            >
              <span :class="{ placeholder: !selectedProducer }">
                {{
                  selectedProducer
                    ? selectedProducer.name
                    : "Seleccionar productor"
                }}
              </span>
              <svg
                class="select-arrow"
                viewBox="0 0 24 24"
                width="16"
                height="16"
              >
                <path d="M7 10l5 5 5-5z" fill="currentColor" />
              </svg>
            </div>

            <!-- Search Input and Options (shows when select is open) -->
            <div v-if="isSelectOpen" class="select-search-wra" >
              <div class="select-search-wrapper">
                <input
                  ref="searchInputRef"
                  type="text"
                  v-model="searchQuery"
                  placeholder="Buscar productor"
                  class="select-search-input"
                  :disabled="isLoading"
                />

                <!-- Options List -->
                <div class="select-options">
                  <div
                    v-for="producer in filteredProducers"
                    :key="producer.id"
                    class="select-option"
                    :class="{
                      'is-selected': selectedProducer?.id === producer.id,
                    }"
                    @click="selectProducer(producer)"
                  >
                    {{ producer.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="input-group">
          <label for="amount">Monto</label>
          <input
            id="amount"
            type="number"
            v-model="amount"
            placeholder="Ingrese el monto"
            class="amount-input"
            :disabled="isLoading"
          />
        </div>
      </div>

      <div class="dialog-footer">
        <button
          class="cancel-button"
          @click="handleClose"
          :disabled="isLoading"
        >
          Cancelar
        </button>
        <button
          class="submit-button"
          @click="handleSubmit"
          :disabled="isLoading || !amount || !selectedProducer"
        >
          {{ isLoading ? "Creando..." : "Crear Pago" }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.select-container {
  position: relative;
  width: 100%;
}

.select-trigger {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
}

.select-trigger:hover {
  border-color: #bbb;
}

.select-trigger.is-open {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.select-arrow {
  transition: transform 0.2s ease;
}

.is-open .select-arrow {
  transform: rotate(180deg);
}

.placeholder {
  color: #000000;
  background-color: #ffffff;
}

.select-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #3b82f6;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 50;
}
.select-search-wra {
 min-height: 43px;
 width: 100%;
}

.select-search-input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
  background: transparent;
  font-size: 1rem;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.select-option {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.select-option:hover {
  background-color: #f3f4f6;
}

.select-option.is-selected {
  background-color: #e5e7eb;
}

/* Make input group relative for proper z-index stacking */
.input-group {
  position: relative;
  z-index: 1;
}

.input-group:has(.select-container) {
  z-index: 2;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background-color: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 500px;
  padding: 1.5rem;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.dialog-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
}

.dialog-body {
  margin-bottom: 1.5rem;
}

.dialog-description {
  margin-bottom: 1rem;
  color: #666;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.amount-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel-button,
.submit-button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.cancel-button {
  background-color: #e5e7eb;
}

.cancel-button:hover {
  background-color: #d1d5db;
}

.submit-button {
  background-color: #21262c;
  color: white;
}

.submit-button:hover {
  background-color: #2b2d30;
}

.submit-button:disabled,
.cancel-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}
</style>
