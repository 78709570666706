<script setup>
import DataTable from "@/components/common/DataTable.vue";
import TextField from "@/components/common/TextField.vue";
import ModalSales from "@/components/ModalSales.vue";
import { useSalesStore } from "@/stores/Sales";
import { computed, onMounted, ref, watch } from "vue";
import useStoreForms from "@/stores/form.js";

const storeForm = useStoreForms();
const salesStore = useSalesStore();
const currentPage = ref(1);

const data = ref([
  {
    product: "Product 1",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
  {
    product: "Product 2",
    name: "Test perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
]);
const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Total vendido",
  },
  {
    width: "w-4/12",
    title: "Total productor",
  },
  {
    width: "w-4/12",
    title: "Monto a abonar",
  },
  {
    width: "w-2/12",
    title: "",
  },
]);

onMounted(async () => {
  if (storeForm.token) {
    await salesStore.getSales();
    await salesStore.getStatus();
  }
});

const changePage = async (page) => {
  if (page < 1 || page > salesStore.pageCount) return;
  currentPage.value = page;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    page,
    state.value
  );
};

// function formatDate(dateString) {
//   const date = new Date(dateString);
//   return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
// }

const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);
const producerName = ref(null);

const search = async () => {
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    producerName.value
  );
};

watch(salesStore.page, async (page, previous) => {
  if (previous !== 0 && page !== 0) {
    await salesStore.getSales(
      product.value,
      dateFrom.value,
      dateTo.value,
      page,
      state.value,
      producerName.value
    );
  }
});

// function formatPrice(price) {
//   return `$${parseFloat(price).toFixed(2)}`;
// }
</script>
<template>
  <section>
    <!-- <div class="mb-20">
      <p class="title-text">Monto recaudado</p>
      <div class="row gap-5 ms-1">
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fs-5 fw-bold">$ {{ salesStore.total }}</p>
          <p class="pb-0 mb-0 fw-medium">Total</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">
            {{ formatPrice(salesStore.sales?.total_amount_us) }}
          </p>
          <p class="pb-0 mb-0 fw-medium">Comision Obtenida</p>
        </div>
      </div>
    </div> -->

    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">Estado de Cuenta</h2>
      </div>
      <div class="d-flex w-100 gap-4">
        <div class="row w-100">
          <div class="col-2">
            <label
              class="w-full font-medium text-background-dark textField"
              for="productCategory"
              >Persona</label
            >
            <select
              class="form-control"
              id="productCategory"
              v-model="account_type"
              required
            >
              <option value="" disabled selected>Seleccionar</option>
              <option v-for="item in data" :key="item" :value="item">
                {{ item.name }}
              </option>
            </select>
            <!-- <TextField
              v-model="producerName"
              id="producerName"
              type="text"
              name="producerName"
              :label="'Persona'"
              placeholder="Buscar "
              @keyup.enter="search()"
            /> -->
          </div>

          <div class="col-2">
            <TextField
              v-model="dateFrom"
              id="dateFrom"
              type="date"
              name="dateFrom"
              label="Desde"
              placeholder="From "
            />
          </div>
          <div class="col-2">
            <TextField
              v-model="dateTo"
              id="dateTo"
              type="date"
              name="dateTo"
              label="Hasta"
              placeholder="To "
            />
          </div>
          <div class="col-2">
            <div class="d-flex gap-4 justify-content-between items-center">
              <div class="w-30">
                <button
                  @click="search()"
                  class="btn bg-skillper-orange mt-4 w-100 text-white"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <DataTable :noHaveData="data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
          <td class="p-3" v-text="'$' + item.total || '0'" />
          <td class="p-3" v-text="'$' + item.total_producer || '0'" />
          <td class="p-3" v-text="'-'" />
          <td class="p-3">
            <p
              class="color-skillper-orange pb-0 mb-0 underline"
              style="cursor: pointer"
            >
              Realizar pago
            </p>
          </td>
        </tr>
      </template>
    </DataTable>
    <nav>
      <ul class="index-nav">
        <li>
          <button
            href="#"
            :class="{
              'cursor-not-allowed opacity-50': salesStore.page === 1,
            }"
            :disabled="salesStore.page === 1"
            @click.prevent="changePage(currentPage - 1)"
          >
            Previous
          </button>
        </li>
        <li v-for="page in salesStore.pageCount" :key="page">
          <button
            :disabled="salesStore.page === page"
            :class="{ 'font-bold': currentPage === page }"
            @click.prevent="changePage(page)"
          >
            {{ page }}
          </button>
        </li>
        <button
          :class="{
            'cursor-not-allowed opacity-50':
              currentPage === salesStore.pageCount,
          }"
          @click.prevent="changePage(currentPage + 1)"
        >
          Next
        </button>
        <li></li>
      </ul>
    </nav>
    <Teleport to="body">
      <ModalSales
        @close="showModalDetail = false"
        v-if="showModalDetail"
        :sales="salesStore.sale"
      />
    </Teleport>
  </section>
</template>
<style scoped>
.refund {
  background: #9c9c9c !important;
}
</style>
