import axios from "axios"
import { defineStore } from "pinia"

export const useProductStore = defineStore("productStore", {
  state: () => ({
    products: [],
    categories: [],
    currentProduct: {
      title: "",
      description: "",
      price: "",
      portraitImage: "",
      categories: [],
      is_sub: false,
      external_url: "",
      modules: [
        {
          title: "",
          lessons: [
            {
              title: "",
              content: "",
              videoUrl: "",
            },
          ],
        },
      ],
    },
    _listProducts: [],
    producers:[]
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    listProducts: (state) => state._listProducts,
  },
  actions: {
    async fetchCategories() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/categories/`
        );
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async createProduct() {
      try {
        const productData = this.currentProduct;
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/products/`,
          productData
        );
        this.products.push(response.data);
        this.resetCurrentProduct();
      } catch (error) {
        console.error("Error creating product:", error);
      }
    },
    async getAllProducts() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/producers/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
          const allUsers = response.data;
          this.producers = allUsers.map(user => ({
            name: `${user.user.first_name} ${user.user.last_name}`,
            auth0_id: user.user.auth0_id,
            id:user.user.id
          }));
          const allProducts = allUsers.flatMap((user) => user.products);
          this._listProducts = allProducts;
      } catch (error) {
        console.error("Error fetching list:", error);
      }
    },

    async getProductsByProducer(auth0Id) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/producers/${encodeURIComponent(auth0Id)}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this._listProducts = response.data.products;
      } catch (error) {
        console.error("Error fetching products by producer:", error);
      }
    },
    async getProductSearch(q) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/products/search/?q=${q}&status=aceptado`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this._listProducts = response.data;
      } catch (error) {
        console.error("Error fetching list:", error);
      }
    },

    async deleteProduct(_id) {
      this.changeStatus("loading");

      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/producer-products/${_id}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 204) {
          this.changeStatus("ready");
        }
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async getProduct(_id) {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/products/${_id}/simple-product/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.currentProduct = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async editProduct(_id, _formData) {
      this.changeStatus("loading");

      try {
        const response = await axios.put(
          `${process.env.VUE_APP_API_URL}/api/products/${_id}/`,
          _formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        if (response.status === 204) {
          this.changeStatus("ready");
        }
        return response;
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async activeInactiveProduct(id, val) {
      const product = this.listProducts.products.find((item) => item.id === id);
      product.active = val;

      const formData = new FormData();
      formData.append("active", val);
      await this.editProduct(id, formData);
    },

    resetCurrentProduct() {
      this.currentProduct = {
        title: "",
        description: "",
        price: "",
        categories: [],
        modules: [
          {
            title: "",
            lessons: [
              {
                title: "",
                content: "",
                videoUrl: "",
              },
            ],
          },
        ],
      };
    },
    addModule() {
      this.currentProduct.modules.push({
        title: "",
        lessons: [
          {
            title: "",
            content: "",
            videoUrl: "",
          },
        ],
      });
    },
    removeModule(moduleIndex) {
      this.currentProduct.modules.splice(moduleIndex, 1);
    },
    addLesson(moduleIndex) {
      this.currentProduct.modules[moduleIndex].lessons.push({
        title: "",
        content: "",
        videoUrl: "",
      });
    },
    removeLesson(moduleIndex, lessonIndex) {
      if (this.currentProduct.modules[moduleIndex].lessons.length > 1) {
        this.currentProduct.modules[moduleIndex].lessons.splice(lessonIndex, 1);
      }
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
