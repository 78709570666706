<template>
  <section  class="overlay">
    <section class="modal-producer">
      <div
        class="m-1 w-100 col-sm-8 col-md-6 col-lg-4 shadow-sm p-3 mb-5 bg-white border rounded"
      >
        <div class="pt-5 pb-5">
          <img
            class="rounded mx-auto d-block"
            src="../assets/logo.png"
            alt=""
            width="90px"
            height="90px"
          />
          <p class="text-center text-uppercase mt-3 fw-bold">Iniciar Sesion</p>
          <form class="form text-start" @submit.prevent="login()">
            <div class="form-group input-group-md mb-2">
              <TextField
                v-model="dataForm.email"
                :label="'Correo electronico'"
                class="mb-2"
                :errorMessage="
                  handlerValidate?.['email']?.$errors?.length > 0
                    ? 'Este campo es requerido'
                    : undefined
                "
              />
            </div>
            <div class="form-group input-group-md">
              <TextField
                v-model="dataForm.password"
                :label="'Contraseña'"
                class="mb-2"
                type="password"
                :errorMessage="
                  handlerValidate?.['password']?.$errors?.length > 0
                    ? 'Este campo es requerido'
                    : undefined
                "
              />
            </div>
            <button
              class="btn bg-skillper-orange mt-4 w-100 text-white"
              type="submit"
            >
              Iniciar Sesion
            </button>
            <!-- <a href="#" class="float-right mt-2">Forgot Password? </a> -->
          </form>
        </div>
      </div>
    </section>
  </section>
</template>
<script setup>
import useStoreForms from "@/stores/form.js";
import { showNotification } from "@/composables/useNotification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ref } from "vue";
import TextField from "./common/TextField.vue";

const storeForm = useStoreForms();
const dataForm = ref({
  aplication_name: "admin",
});

const handlerValidate = useVuelidate(
  {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  dataForm.value
);

const login = async () => {
  const _validate = await handlerValidate.value.$validate();
  if (!_validate) return;

  await storeForm.login(dataForm.value);

  if (storeForm.isReady) {
    showNotification("Inicio de sesion exitoso", "success");
  }

  if (storeForm.isError) {
    if (storeForm.error?.detail) {
      showNotification(storeForm.error?.detail, "error");
    } else {
      showNotification("Ocurrio un error", "error");
    }
  }
};
</script>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  z-index: 99999;
  background: #252525;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-producer {
  border-radius: 10px;
  text-align: center;
  width: 320px;
}
.modal-producer .title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1rem;
}
.modal-producer svg {
  width: 50px;
  height: 50px;
  color: orange;
}
</style>
