<script setup>
import DataTable from "@/components/common/DataTable.vue";
import TextField from "@/components/common/TextField.vue";
import ModalSales from "@/components/ModalSales.vue";
import { showNotification } from "@/composables/useNotification";
import { useSalesStore } from "@/stores/Sales";
import { computed, onMounted, ref, watch } from "vue";
import { Pencil } from "lucide-vue-next";

const salesStore = useSalesStore();
const currentPage = ref(1);

const data = ref([
  {
    product: "Product 1",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
  {
    product: "Product 2",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
]);
const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
  },
  {
    width: "w-4/12",
    title: "Cliente",
  },
  {
    width: "w-4/12",
    title: "Productor",
  },
  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },
  {
    width: "w-2/12",
    title: "Total de venta",
  },
  /* {
    width: "w-2/12",
    title: "Comisión Obtenida",
  }, */
  {
    width: "w-2/12",
    title: "Estado de venta",
  },
  {
    width: "w-2/12",
    title: "Detalle",
  },
  {
    width: "w-2/12",
    title: "Estado",
  },
]);

onMounted(async () => {
  await salesStore.getSales();
  await salesStore.getStatus();
});

const changePage = async (page) => {
  if (page < 1 || page > salesStore.pageCount) return;
  currentPage.value = page;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    page,
    state.value
  );
};

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);
const producerName = ref(null);
const editingState = ref(null);
const originalState = ref(null);

const startEditing = (item) => {
  editingState.value = item.id;
  originalState.value = item.status.value;
};

const handleBlur = (item, event) => {
  if (
    !event.relatedTarget ||
    !event.currentTarget.contains(event.relatedTarget)
  ) {
    editingState.value = null;
    if (item.status.value) item.status.value = originalState?.value;
  }
};
const search = async () => {
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    producerName.value
  );
};

watch(salesStore.page, async (page, previous) => {
  if (previous !== 0 && page !== 0) {
    await salesStore.getSales(
      product.value,
      dateFrom.value,
      dateTo.value,
      page,
      state.value,
      producerName.value
    );
  }
});
const showModalDetail = ref(false);
const modalDetail = async (id) => {
  await salesStore.getSale(id);
  showModalDetail.value = true;
};

function formatPrice(price) {
  return `$${parseFloat(price).toFixed(2)}`;
}

const refund = async (id) => {
  await salesStore.refund({
    sale_id: JSON.stringify(id),
  });
  if (salesStore.isReady) {
    showNotification("Actualizado correctamente", "success");
    await salesStore.getSales();
  } else {
    showNotification("Ocurrio un error", "error");
  }
};
const handleStatusToggle = async (item) => {
  const getIdByValue = (value) => {
    switch (value.toLowerCase()) {
      case "reembolsada":
        return 1;
      case "cancelada":
        return 2;
      case "archivada":
        return 3;
      case "confirmada":
        return 4;
      case "pagada":
        return 5;
      case "completada":
        return 6;
      default:
        return null;
    }
  };
  const id_value = getIdByValue(item.status.value);

  const success = await salesStore.updateSaleStatus(item.id, id_value);

  if (success) {
    showNotification("Estado actualizado correctamente", "success");
    editingState.value = null;
    await salesStore.getSales();
    await salesStore.getStatus();
    item.isEditing = false;
  } else {
    editingState.value = null;
    showNotification("Error al actualizar el estado", "error");
  }
};
</script>
<template>
  <section>
    <div class="mb-20">
      <p class="title-text">Monto recaudado</p>
      <div class="row gap-5 ms-1">
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fs-5 fw-bold">$ {{Math.round(salesStore.total * 100) / 100 }}</p>
          <p class="pb-0 mb-0 fw-medium">Total</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">
            {{ formatPrice(salesStore.sales?.total_amount_us) }}
          </p>
          <p class="pb-0 mb-0 fw-medium">Comision Obtenida</p>
        </div>
      </div>
    </div>

    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.SALES") }}</h2>
      </div>
      <div class="d-flex w-100 gap-4">
        <div class="row w-100">
          <div class="col-2">
            <TextField
              v-model="product"
              id="search"
              type="text"
              name="productPrice"
              :label="'Producto'"
              placeholder="Buscar "
              @keyup.enter="search()"
            />
          </div>
          <div class="col-2">
            <TextField
              v-model="producerName"
              id="producerName"
              type="text"
              name="producerName"
              :label="'Productor'"
              placeholder="Buscar "
              @keyup.enter="search()"
            />
          </div>
          <div class="col-2">
            <div class="w-100">
              <p v-text="'Estado'" class="mb-0" />
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="state"
              >
                <option value="" selected>Seleccionar</option>
                <option
                  v-for="(status, index) in salesStore.statusSales"
                  :key="index"
                  :value="status.id"
                >
                  {{ status.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <TextField
              v-model="dateFrom"
              id="dateFrom"
              type="date"
              name="dateFrom"
              label="Desde"
              placeholder="From "
            />
          </div>
          <div class="col-2">
            <TextField
              v-model="dateTo"
              id="dateTo"
              type="date"
              name="dateTo"
              label="Hasta"
              placeholder="To "
            />
          </div>
          <div class="col-2">
            <div class="d-flex gap-4 justify-content-between items-center">
              <!-- <div class="w-100">
                <p v-text="'Estado'" class="mb-0" />
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="state"
                >
                  <option value="" selected>Seleccionar</option>
                  <option
                    v-for="(status, index) in salesStore.statusSales"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.value }}
                  </option>
                </select>
              </div> -->
              <div class="w-30">
                <button
                  @click="search()"
                  class="btn bg-skillper-orange mt-4 w-100 text-white"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="table-container">
      <DataTable :noHaveData="data?.length === 0" :headers="titlesTable">
        <template #body>
          <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
            <!-- Sticky column -->
            <td class="sticky-column" v-text="item.product.title || '-'" />

            <!-- Other columns -->
            <td class="table-cell" v-text="item?.user?.username || '-'" />
            <td
              class="table-cell"
              v-text="item?.producer?.user?.first_name || '-'"
            />
            <td class="table-cell" v-text="item.user.email || '-'" />
            <td
              class="table-cell"
              v-text="formatDate(item.created_at) || '-'"
            />

            <td class="p-3" v-text="'$' + item.total || '0'" />
            <td class="table-cell">
              <div class="status-container">
                <template v-if="editingState !== item.id">
                  <span>{{ item?.status?.value || "-" }}</span>
                  <Pencil
                    @click="startEditing(item)"
                    class="edit-icon"
                    :size="16"
                  />
                  <div class="tooltip">Click para editar el estado</div>
                </template>
                <div v-else class="edit-controls">
                  <select
                    v-model="item.status.value"
                    @change="handleStatusToggle(item)"
                    @blur="handleBlur(item, $event)"
                    class="select-input"
                  >
                    <option value="Reembolsada">Reembolsada</option>
                    <option value="Cancelada">Cancelada</option>
                    <option value="Archivada">Archivada</option>
                    <option value="Confirmada">Confirmada</option>
                    <option value="Pagada">Pagada</option>
                    <option value="Completada">Completada</option>
                  </select>
                </div>
              </div>
            </td>
            <!--             <td class="table-cell" v-text="'$'+item.total_affiliate || '0'" />
 -->
            <td class="table-cell">
              <p
                class="color-skillper-orange pb-0 mb-0 underline"
                style="cursor: pointer"
                @click="modalDetail(item.id)"
              >
                Ver detalle
              </p>
            </td>
            <td class="table-cell">
              <button
                :class="{ refund: item.status?.value === 'Reembolsada' }"
                :disabled="item.status?.value === 'Reembolsada'"
                @click="refund(item.id)"
                class="btn bg-skillper-orange text-white"
              >
                {{
                  item.status?.value === "Reembolsada"
                    ? "Reembolsada"
                    : "Reembolsar"
                }}
              </button>
            </td>
          </tr>
        </template>
      </DataTable>
    </div>
    <nav>
      <ul class="index-nav">
        <li>
          <button
            href="#"
            :class="{
              'cursor-not-allowed opacity-50': salesStore.page === 1,
            }"
            :disabled="salesStore.page === 1"
            @click.prevent="changePage(currentPage - 1)"
          >
            Previous
          </button>
        </li>
        <li v-for="page in salesStore.pageCount" :key="page">
          <button
            :disabled="salesStore.page === page"
            :class="{ 'font-bold': currentPage === page }"
            @click.prevent="changePage(page)"
          >
            {{ page }}
          </button>
        </li>
        <button
          :class="{
            'cursor-not-allowed opacity-50':
              currentPage === salesStore.pageCount,
          }"
          @click.prevent="changePage(currentPage + 1)"
        >
          Next
        </button>
        <li></li>
      </ul>
    </nav>
    <Teleport to="body">
      <ModalSales
        @close="showModalDetail = false"
        v-if="showModalDetail"
        :sales="salesStore.sale"
      />
    </Teleport>
  </section>
</template>
<style scoped>
.status-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.edit-icon {
  cursor: pointer;
  color: #666;
  transition: color 0.2s ease;
}

.edit-icon:hover {
  color: #000;
}

.tooltip {
  visibility: hidden;
  position: relative;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  white-space: nowrap;
  z-index: 10;
}

.select-input {
  padding: 0.25rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  font-size: inherit;
}

.edit-controls {
  display: flex;
  align-items: center;
}
.refund {
  background: #9c9c9c !important;
}
.table-container {
  width: 100%;
  height: 500px; /* Adjust this value based on your needs */
  overflow: auto;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

/* Make sure the table takes full width but allows content-based column widths */
:deep(table) {
  width: max-content;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

/* Style for sticky column */
.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
  padding: 0.75rem;
  white-space: nowrap;
  box-shadow: 2px 0 4px -2px rgba(0, 0, 0, 0.15);
}

/* Make the header sticky at the top and the first column header sticky in both directions */
:deep(thead) {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

:deep(thead th) {
  background-color: white;
  padding: 0.75rem;
}

:deep(thead th:first-child) {
  position: sticky;
  left: 0;
  z-index: 3; /* Higher z-index to stay on top when scrolling */
  box-shadow: 2px 0 4px -2px rgba(0, 0, 0, 0.15);
}

/* Regular table cell styling */
.table-cell {
  padding: 0.75rem;
  white-space: nowrap;
  background-color: white;
}

/* Optional: Add smooth scrolling */
.table-container {
  scroll-behavior: smooth;
}

/* Style for table rows */
:deep(tr) {
  border-bottom: 1px solid #e5e7eb;
}

:deep(tr:last-child) {
  border-bottom: none;
}

/* Add some hover effect to rows */
:deep(tbody tr:hover) {
  background-color: #f9fafb;
}

:deep(tbody tr:hover) .sticky-column {
  background-color: #f9fafb;
}
</style>
